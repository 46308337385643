import { memo, useCallback, useContext, useState } from 'react';
import SnackbarContext from 'src/contexts/SnackbarContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { func, string } from 'prop-types';
import ConfirmAction from '@components/warnings/ConfirmAction';
import { checkAuthError } from 'src/utilize/helper-functions';

import { StyledChatMenuItem } from '../styles';

MenuItemCreateProjectFC.propTypes = {
  toggleOptionsMenu: func,
  taskId: string,
};

function MenuItemCreateProjectFC({ toggleOptionsMenu, taskId }) {
  const [confirmProjectCreation, setConfirmProjectCreation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState();
  const { showSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const handleProjectCreation = useCallback(() => {
    if (!taskId) return;
    setIsSubmitting(true);
    axios
      .patch('/api/task_to_project', { task_id: taskId })
      .then((response) => navigate(`/projects/${response.data.result}`))
      .catch((error) => {
        if (checkAuthError(error)) {
          showSnackbar('У вас нет доступа для создания проекта');
        } else showSnackbar('Возникла ошибка при создании проекта');
      })
      .finally(() => setIsSubmitting(false));
  }, [taskId]);

  return (
    <>
      <StyledChatMenuItem
        onClick={() => {
          toggleOptionsMenu(false);
          setConfirmProjectCreation(true);
        }}
      >
        Создать проект из задачи
      </StyledChatMenuItem>

      {confirmProjectCreation && (
        <ConfirmAction
          actionText="Вы уверены что хотите создать проект из этой задачи?"
          confirmButtonText="Подтвердить"
          confirm={handleProjectCreation}
          cancel={() => setConfirmProjectCreation(false)}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
}

const MenuItemCreateProject = memo(MenuItemCreateProjectFC);

export default MenuItemCreateProject;
