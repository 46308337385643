import { useEffect, useContext, useState, useCallback, createContext, useRef, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DropFilesWrapper from '@components/files/DropFilesWrapper';
import StoragesDropdown from 'src/components/storage/StoragesDropdown';
import Preloader from 'src/components/preloaders/Preloader';
import StorageTree from 'src/components/storage/StorageTree';
import StorageContent from 'src/components/storage/StorageContent';
import { useEventTriggerOnEscPress } from 'src/utilize/helper-functions';
import SnackbarContext from 'src/contexts/SnackbarContext';
import {
  clearState,
  clearStorageSearchResults,
  getStorageData,
  getStorageTree,
  setOpenFolder,
} from 'src/redux/features/storageSlice';
import StorageDescription from 'src/components/storage/StorageDescription';
import SearchStorage from 'src/components/storage/SearchStorage';
import NotFoundComponent from '@shared/components/NotFoundComponent/NotFoundComponent';
import ModalPortal from '@shared/components/Modal/ModalPortal';
import { Storage } from '@components/storage/styles';
import {
  StyledCloseModalButton,
  StyledModal,
  StyledModalBody,
  StyledModalInner,
  StyledModalTitleWrapper,
  StyledModalWrapper,
} from '@shared/components/Modal/style';
import { AddFilesModalV2 } from '@components/storage/AddFilesModalV2/AddFilesModalV2';
import { Container, Text } from '@shared/components';
import { AddFolderModalV2 } from '@components/storage/AddFolderModalV2/AddFolderModalV2';
import { DropFoldersAndFilesModalV2 } from '@components/storage/DropFoldersAndFilesModalV2';
import { Avatar } from '@shared/components/Avatar';

import CreateStorageModal from './CreateStorageModal';
import { StyledStorageHeader, StyledStorageTitle, StyledTextReference } from './styles';

const StorageContext = createContext({
  addStorageFileReference: null,
});

export const useStorageContext = () => {
  const context = useContext(StorageContext);

  if (context) {
    return context;
  }
  throw new Error('useMessageContext must be used within a MessageContextProvider');
};

const StorageModal = ({ storageId, folderId, close, noURLParams, addStorageFileReference, attachFolderReference }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  const [storageIdToReq, setStorageIdToReq] = useState(Number(storageId) || null);
  const [openFolderId, setOpenFolderId] = useState(Number(folderId) || null);

  const { showSnackbar } = useContext(SnackbarContext);
  const params = useParams();

  // при изменении URL параметра, обновить storageid и folderId в state, эти id в дальнейшем используются для отправки запросов
  useEffect(() => {
    if (params?.storageId || params?.folderId) {
      setStorageIdToReq(Number(params.storageId) || null);
      setOpenFolderId(params.folderId || null);
    }
  }, [params]);

  const location = useLocation();

  const navigate = useNavigate();
  const { storageData, notFound, isLoading, storageTree, storageTreeError } = useSelector((state) => state.storage);

  const [editStorageModal, setEditStorageModal] = useState();
  const [addFolderModal, setAddFolderModal] = useState();
  const [addFilesModal, setAddFilesModal] = useState();

  const handleClose = () => {
    const versionsModal = document.querySelector('[data-popup]');

    if (editStorageModal || addFolderModal || addFolderModal || addFilesModal || versionsModal) {
      return;
    }

    if (typeof close === 'function') {
      close();
      return;
    }
    navigate(location?.state?.from?.pathname || '/storages');
  };

  useEventTriggerOnEscPress(handleClose);

  const getData = useCallback((storageIdToReq) => {
    dispatch(clearState());
    dispatch(getStorageData({ storageId: storageIdToReq, showSnackbar }));
    dispatch(getStorageTree({ storageId: storageIdToReq, showSnackbar }));
  }, []);

  const requestedStorageId = useRef();
  // получение данных о хранилище
  useEffect(() => {
    if (!storageIdToReq || requestedStorageId.current === storageIdToReq) return;
    if (!storageData?.storage || storageData?.storage.id !== +storageIdToReq) {
      getData(storageIdToReq);
      requestedStorageId.current = storageIdToReq;
    }
  }, [storageIdToReq, storageData]);

  const handleAnotherStorageSelect = useCallback(
    (storageData) => {
      if (location?.pathname.includes('storages')) {
        navigate(`/storages/${storageData.id}`);
      } else if (typeof close === 'function') {
        setStorageIdToReq(storageData.id);
      }
    },
    [navigate, location, close],
  );

  const handleFolderSelect = useCallback(
    (folderId) => {
      dispatch(clearStorageSearchResults());
      if (noURLParams) return setOpenFolderId(folderId);
      navigate(`/storages/${params.storageId}/folder/${folderId}`);
    },
    [navigate, noURLParams, params],
  );

  const [droppedItems, setDroppedItems] = useState();

  const storageContextValue = useMemo(() => ({ addStorageFileReference }), [addStorageFileReference]);

  return (
    <>
      <ModalPortal>
        <StyledModal>
          <StyledModalWrapper style={{ padding: '15px' }}>
            <StyledModalInner modalsize="1200px" style={{ height: '100%' }}>
              <DropFilesWrapper setDroppedItems={setDroppedItems} disabled={!storageData?.can_write}>
                <StyledModalBody style={{ overflow: 'auto', height: '100%' }}>
                  {/* 
                    //#region STORAGE HEADER
                  */}
                  <StyledStorageHeader>
                    <StyledModalTitleWrapper>
                      <StyledStorageTitle
                        onClick={() => {
                          setOpenFolderId(null);
                          dispatch(clearStorageSearchResults());
                          if (noURLParams) {
                            dispatch(setOpenFolder({ openFolder: storageTree, path: null }));
                            return;
                          }
                          navigate(`/storages/${storageIdToReq}`);
                        }}
                      >
                        {storageData?.storage?.title}
                      </StyledStorageTitle>
                      <StorageDescription storageData={storageData} setEditStorageModal={setEditStorageModal} />
                    </StyledModalTitleWrapper>

                    <StoragesDropdown position="absolute" handleAnotherStorageSelect={handleAnotherStorageSelect} />
                    <SearchStorage />

                    <StyledCloseModalButton onClick={handleClose}></StyledCloseModalButton>
                  </StyledStorageHeader>
                  {/* 
                  //#endregion
                  */}

                  {!storageIdToReq && !storageTree && !isLoading && (
                    <Text size={3} style={{ hyphens: 'none' }}>
                      Нет прикрепленного хранилища. Можно выбрать хранилище из списка.
                    </Text>
                  )}

                  {storageId &&
                    storageTreeError === 'UNAUTHORIZED' &&
                    !storageTree &&
                    !isLoading &&
                    storageData?.storage?.creator_id && (
                      <Container direction="column" gap="15px">
                        <Text size={3} tag="div" style={{ hyphens: 'none' }}>
                          У вас нет доступа к привязанному хранилищу.
                        </Text>
                        <StyledTextReference size={3} tag="div">
                          <span>Обратитесь к создателю хранилища:&nbsp;&nbsp;</span>
                          <Avatar userId={storageData.storage.creator_id} />{' '}
                          <span>{storageData.storage.creator_first_name || ''}&nbsp;</span>
                          <span>{storageData.storage.creator_last_name || ''}</span>
                        </StyledTextReference>
                      </Container>
                    )}

                  {notFound && <NotFoundComponent small />}

                  {!notFound && (
                    <>
                      {isLoading && <Preloader />}
                      {!isLoading && storageData && storageTree && (
                        <Storage>
                          <StorageTree openFolderId={openFolderId} handleFolderSelect={handleFolderSelect} />
                          <StorageContext.Provider value={storageContextValue}>
                            <StorageContent
                              setAddFilesModal={setAddFilesModal}
                              setAddFolderModal={setAddFolderModal}
                              handleFolderSelect={handleFolderSelect}
                              openFolderId={openFolderId}
                              setOpenFolderId={setOpenFolderId}
                              noURLParams={noURLParams}
                              storageIdToReq={storageData.storage.id}
                              addStorageFileReference={addStorageFileReference}
                              attachFolderReference={attachFolderReference}
                            />
                          </StorageContext.Provider>
                        </Storage>
                      )}
                    </>
                  )}
                </StyledModalBody>
              </DropFilesWrapper>
            </StyledModalInner>
          </StyledModalWrapper>
        </StyledModal>
      </ModalPortal>

      {addFolderModal && (
        <AddFolderModalV2
          dataType={'storage'}
          folderId={params.folderId || openFolderId}
          setOpenFolderId={setOpenFolderId}
          storageId={storageIdToReq}
          close={() => setAddFolderModal(false)}
        />
      )}

      {addFilesModal && (
        <AddFilesModalV2
          close={() => setAddFilesModal(false)}
          storageIdToReq={storageIdToReq}
          folderId={params.folderId || openFolderId}
        />
      )}

      {editStorageModal && (
        <CreateStorageModal close={() => setEditStorageModal(false)} editData={storageData} getData={getData} />
      )}

      {droppedItems && (
        <DropFoldersAndFilesModalV2
          droppedItems={droppedItems}
          close={() => setDroppedItems(null)}
          storageId={storageIdToReq}
          folderId={params.folderId || openFolderId}
        />
      )}
    </>
  );
};

export default StorageModal;
