import axios from 'axios';
import { useEffect, useState, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { formatDateWithDots } from '@shared/components/CalendarForm/CalendarForm';
import { isFileNameImage, isPreviewFile, useEventTriggerOnEscPress } from 'src/utilize/helper-functions';
import { FileLoader } from '@shared/widgets/FileLoader';
import { useDownloader } from '@shared/hooks/useDownloader';
import { Modal, Subtitle } from '@shared/components';
import ConfirmAction from '@components/warnings/ConfirmAction';
import FileIcon from 'src/assets/images/icons/icon-file.svg';
import { files_url } from 'src/settings/base-url';
import useAuth from 'src/hooks/useAuth';
import SnackbarContext from 'src/contexts/SnackbarContext';
import Preloader from 'src/components/preloaders/Preloader';

import FolderVersionRowMenu from './FolderVersionsRowMenu';
import { CatalogFile, CatalogFileName, StorageList, StyledIcon, StyledImageIcon } from './styles';

const FileVersionsModal = ({ close, currentFileData, renderUserName, userCanWrite }) => {
  useEventTriggerOnEscPress(close);

  const [fileVersions, setFileVersions] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [previewFile, setPreviewFile] = useState({ url: '', fileName: '', filePath: '' });
  const [downloadableFile, setDownloadableFile] = useState({ url: '', fileName: '', filePath: '' });

  const [previewModalVisible, setPreviewModalVisible] = useState(false);

  const [confirmModal, setConfirmModal] = useState(false);
  const [fileSize, setFileSize] = useState(0);

  const auth = useAuth();
  const { storageId } = useParams();

  const { showSnackbar } = useContext(SnackbarContext);

  const { downloadFile } = useDownloader();

  const onConfirm = () => {
    downloadFile({
      url: downloadableFile.url,
      filename: downloadableFile.fileName,
      token: auth.token,
      forceDownload: true,
    });
    setConfirmModal(false);
  };

  useEffect(() => {
    if (currentFileData) {
      axios
        .get(`/api/storage_file_history/${currentFileData.id}`)
        .then((r) => {
          const { storage_file_history } = r.data;
          setFileVersions(storage_file_history);
          setIsLoading(false);
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при загрузке предыдущих версий файла');
          setIsLoading(false);
        });
    }
  }, [currentFileData, showSnackbar]);

  // useEffect(() => {
  //   if (!!previewFile.fileName.length) setPreviewModalVisible(true);
  // }, [previewFile]);

  const currentFileUrl = `${files_url}/storages/${currentFileData.path}?token=${auth.token}`;
  const isCurrentFileImage = useMemo(() => isFileNameImage(currentFileData.title));

  if (!auth?.token) return '';
  return (
    <Modal title="История версий" onClose={close} withoutButtons>
      <div data-popup="storageModalPopup"></div>
      <Subtitle>Текущая</Subtitle>
      <StorageList>
        <CatalogFile
          onClick={() => {
            if (isPreviewFile(currentFileData.title) || isCurrentFileImage) {
              setPreviewFile({
                // url: `${files_url}/storages/${currentFileData.path}?token=${auth.token}`,
                url: currentFileUrl,
                fileName: currentFileData.title,
                filePath: `/storages/${currentFileData.path}`,
              });
            } else {
              setDownloadableFile({
                url: `/storages/${currentFileData.path}`,
                fileName: currentFileData.title,
                filePath: `/storages/${currentFileData.path}`,
              });

              downloadFile({
                url: `/storages/${currentFileData.path}`,
                filename: currentFileData.title,
                token: auth.token,
              }).then(({ fileDetails }) => {
                if (fileDetails.isLarge) {
                  setConfirmModal(true);
                  setFileSize(fileDetails.size);
                }
              });
            }
          }}
        >
          {isCurrentFileImage ? (
            <StyledImageIcon src={currentFileUrl} loading="lazy" />
          ) : (
            <StyledIcon $fileUrl={FileIcon} />
          )}
          <CatalogFileName>{currentFileData.title}</CatalogFileName>
          <p>{/*20 мб*/}</p>
          <p>{formatDateWithDots(currentFileData.date_created)}</p>
          {renderUserName(currentFileData.creator_id)}

          <FileLoader url={`/storages/${currentFileData.path}`} filename={currentFileData.title} token={auth.token} />
        </CatalogFile>
      </StorageList>

      {isLoading && <Preloader />}
      {fileVersions && !isLoading && (
        <>
          <Subtitle>Предыдущие</Subtitle>
          <StorageList>
            {fileVersions.map((versionData, i) => {
              const isImage = isFileNameImage(versionData.title);
              const fileFullUrl = `${files_url}/storages/versions/files/${storageId || currentFileData?.storage_id}/${
                versionData.id
              }/${versionData.title}?token=${auth.token}`;

              return (
                <CatalogFile
                  key={i}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isPreviewFile(versionData.title) || isImage) {
                      setPreviewFile({
                        url: fileFullUrl,
                        fileName: versionData.title,
                        filePath: `/storages/versions/files/${storageId || currentFileData?.storage_id}/${
                          versionData.id
                        }/${versionData.title}`,
                      });
                    } else {
                      setDownloadableFile({
                        url: `/storages/versions/files/${storageId || currentFileData?.storage_id}/${versionData.id}/${
                          versionData.title
                        }`,
                        fileName: versionData.title,
                        filePath: `/storages/versions/files/${storageId || currentFileData?.storage_id}/${
                          versionData.id
                        }/${versionData.title}`,
                      });

                      downloadFile({
                        url: `/storages/versions/files/${storageId || currentFileData?.storage_id}/${versionData.id}/${
                          versionData.title
                        }`,
                        filename: versionData.title,
                        token: auth.token,
                      }).then(({ fileDetails }) => {
                        if (fileDetails.isLarge) {
                          setConfirmModal(true);
                          setFileSize(fileDetails.size);
                        }
                      });
                    }
                  }}
                >
                  {isImage ? <StyledImageIcon src={fileFullUrl} loading="lazy" /> : <StyledIcon $fileUrl={FileIcon} />}
                  <CatalogFileName>{versionData.title}</CatalogFileName>
                  <p>{/*20 мб*/}</p>
                  <p>{formatDateWithDots(versionData.date_created)}</p>
                  {renderUserName(versionData.creator_id)}

                  <FileLoader
                    url={`/storages/versions/files/${storageId || currentFileData?.storage_id}/${versionData.id}/${
                      versionData.title
                    }`}
                    filename={versionData.title}
                    token={auth.token}
                  />

                  <FolderVersionRowMenu
                    dataType="file"
                    versionData={versionData}
                    setIsSubmitting={setIsLoading}
                    userCanWrite={userCanWrite}
                  />
                </CatalogFile>
              );
            })}
          </StorageList>
        </>
      )}

      {/*временно убрали этот функционал*/}
      {/*{previewModalVisible && (*/}
      {/*  <PreviewModal*/}
      {/*    url={previewFile.url}*/}
      {/*    fileName={previewFile.fileName}*/}
      {/*    filePath={previewFile.filePath}*/}
      {/*    onClose={() => {*/}
      {/*      setPreviewModalVisible(false);*/}
      {/*      setPreviewFile({url: '', fileName: '', filePath: ''});*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}

      {confirmModal && (
        <ConfirmAction
          cancel={() => setConfirmModal(false)}
          confirm={onConfirm}
          actionText={`Вы уверены, что хотите скачать файл? Он весит ${fileSize} Мб`}
          confirmButtonText={'Да'}
          cancelButtonText={'Нет'}
          isSuccess
        />
      )}
    </Modal>
  );
};

export default FileVersionsModal;
