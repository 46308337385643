import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formatDateWithDots } from '@shared/components/CalendarForm/CalendarForm';
import { Avatar } from '@shared/components/Avatar';
import Tag from '@shared/components/Tag/Tag';

import HTMLReactParser from 'html-react-parser';

import {
  ProjectCard,
  ProjectCardDate,
  ProjectCardDescription,
  ProjectCardHeader,
  ProjectCardStatus,
  ProjectCardTags,
  ProjectCardTitle,
  ProjectCardUser,
  ProjectCardUsername,
} from '../Projects/project-list/project-list-container/style';

import { ParentProjectWrapper, ProjectLink } from './style';

const taskStatus = {
  created: { text: 'Создана', color: 'grey' },
  in_progress: { text: 'В работе', color: 'yellow' },
  finished: { text: 'Завершена', color: 'green' },
};

const TaskListItem = ({ task }) => {
  const { t } = useTranslation();

  const {
    parent_task_id,
    project_id,
    id,
    title,
    description,
    status,
    date_finish,
    date_start,
    project_title,
    root_project,
    project_date_start,
    project_date_finish,
    responsible_first_name,
    responsible_last_name,
    responsible_id,
    project_tags,
    task_tags,
  } = task;

  const tags = project_tags || task_tags;

  return (
    <>
      <ProjectCard>
        <ProjectCardHeader>
          <ProjectCardDate>
            {(date_start || date_finish) && (
              <>
                {date_start && formatDateWithDots(date_start)} - {date_finish && formatDateWithDots(date_finish)}
              </>
            )}
          </ProjectCardDate>

          {taskStatus[status] && (
            <ProjectCardStatus color={taskStatus[status].color}>
              {taskStatus[status].text}
              {/*<ProjectIconNotify icon={iconNotify} />*/}
            </ProjectCardStatus>
          )}
        </ProjectCardHeader>

        {/*ссылка на задачу*/}
        <Link
          to={
            parent_task_id || project_id
              ? `/projects/${!!project_id ? project_id : parent_task_id}/tasks/${id}`
              : `/projects/${id}`
          }
        >
          <ProjectCardTitle>{title}</ProjectCardTitle>
        </Link>

        {/*ссылка на родительский проект*/}
        {project_title && (
          <ParentProjectWrapper>
            Проект:
            <ProjectLink to={`/projects/${root_project || project_id}`}>{project_title}</ProjectLink>
            {/*{(project_date_start || project_date_finish) && (*/}
            {/*  <span>*/}
            {/*  ({project_date_start && formatDateWithDots(project_date_start)} -{' '}*/}
            {/*    {project_date_finish && formatDateWithDots(project_date_finish)})*/}
            {/*  </span>*/}
            {/*)}*/}
          </ParentProjectWrapper>
        )}

        {/* format--description - класс для того, чтобы было видно форматирование через тэги  */}
        {description && (
          <ProjectCardDescription>
            {typeof myVar === 'string' ? HTMLReactParser(description) : ''}
          </ProjectCardDescription>
        )}
        <span>{t('Project.responsible')}</span>
        <ProjectCardUser>
          <Avatar userId={responsible_id} firstName={responsible_first_name} lastName={responsible_last_name} />
          <ProjectCardUsername>{`${responsible_first_name || ''} ${responsible_last_name || ''}`}</ProjectCardUsername>
        </ProjectCardUser>
        <ProjectCardTags>
          {tags?.map((item, i) => (
            <Tag key={i} tag={item} />
          ))}
        </ProjectCardTags>
      </ProjectCard>
    </>
  );
};

export default TaskListItem;
