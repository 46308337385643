import { renderAsync } from 'docx-preview';
import { string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Preloader from '@components/preloaders/Preloader';
import axios from 'axios';
import SnackbarContext from 'src/contexts/SnackbarContext';
import ZoomInIcon from '@assets/images/icons/zoom-in.svg';
import ZoomOutIcon from '@assets/images/icons/zoom-out.svg';
import ResetScaleIcon from '@assets/images/icons/reset-scale.svg';

import { StyledButtonsContainer, StyledDocContainer, StyledIconButton } from './style';

DocViewerDOCXPreview.propTypes = {
  file: string,
};

export function DocViewerDOCXPreview({ file }) {
  const [showLoading, setShowLoading] = useState(false);
  const [isRendered, setIsRendered] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  const loadContent = useCallback(
    async (arrayBuffer) => {
      setShowLoading(true);

      renderAsync(arrayBuffer, document.getElementById('docx-preview-container'), null, {
        breakPages: false,
        renderHeaders: false,
        renderEndnotes: false,
        renderFootnotes: false,
        renderFooters: false,
        ignoreHeight: true,
        ignoreWidth: true,
      })
        .then(() => setIsRendered(true))
        .catch(() => showSnackbar('Не удалось отобразить файл в браузере'))
        .finally(() => setShowLoading(false));
    },
    [showSnackbar],
  );

  const getFile = useCallback(async () => {
    setShowLoading(true);
    if (typeof file === 'string' && file.length) {
      try {
        await axios.get(file, { responseType: 'blob' }).then((response) => {
          loadContent(response.data);
        });
      } catch (e) {
        showSnackbar('Не удалось загрузить файл');
        setShowLoading(false);
      }
    } else {
      showSnackbar('Путь к файлу не найден');
      setShowLoading(false);
    }
  }, [file, showSnackbar]);

  useEffect(() => {
    getFile();
  }, [getFile]);

  const [scale, setScale] = useState(1);

  return (
    <div>
      {showLoading && <Preloader />}
      {isRendered && (
        <StyledButtonsContainer align="center" justify="flex-end" gap="10px">
          <StyledIconButton
            icon={ZoomOutIcon}
            isDisabled={scale < 0.4}
            size="20"
            onClick={() => setScale((scale) => scale - 0.1)}
          />
          <StyledIconButton
            icon={ZoomInIcon}
            size="20"
            isDisabled={scale > 1.4}
            onClick={() => setScale((scale) => scale + 0.1)}
          />

          <StyledIconButton icon={ResetScaleIcon} size="16" isDisabled={scale === 1} onClick={() => setScale(1)} />
        </StyledButtonsContainer>
      )}
      <StyledDocContainer $scale={scale} id="docx-preview-container"></StyledDocContainer>
    </div>
  );
}
