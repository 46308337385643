import { memo, useMemo } from 'react';
import { Radio } from '@shared/components';
import { useController } from 'react-hook-form';

import { StyledWrapper } from './style';

export const TaskTypeRadioSelect = memo(TaskTypeRadioSelectFC);

function TaskTypeRadioSelectFC({ control }) {
  const { field } = useController({ control, name: 'type' });

  const radioButtons = useMemo(
    () => [
      {
        id: 'var1',
        label: 'Просьба',
        value: 'request',
        name: 'type',
        checked: field.value === 'request',
        onChange: () => field.onChange('request'),
      },
      {
        id: 'var2',
        label: 'Раздел',
        value: 'rubric',
        name: 'type',
        checked: field.value === 'rubric',
        onChange: () => field.onChange('rubric'),
      },
    ],
    [field?.onChange, field?.value],
  );

  const handleRadioClick = (e) => e.target.value === field.value && field.onChange(null);

  return (
    <StyledWrapper>
      <Radio items={radioButtons} onClick={handleRadioClick} />
    </StyledWrapper>
  );
}

export default TaskTypeRadioSelect;
