import { func, bool } from 'prop-types';

import { Button } from '@shared/components';
import { useEffect, useRef, useState } from 'react';

GetTasksButton.propTypes = {
  getTasks: func,
  isValid: bool,
  handleSubmit: func,
};

export function GetTasksButton({ getTasks, isValid, handleSubmit }) {
  const [isLoading, setIsLoading] = useState(false);

  const submitRequest = async (values) => {
    const filterObj = {};

    if (Array.isArray(values?.relation)) {
      values.relation.forEach((option) => (filterObj[option.value] = true));
    }
    if (Array.isArray(values?.tags)) {
      filterObj.tags = values.tags.filter((tag) => tag.selected).map((tag) => tag.tagId);
    }
    if (values.subordinate?.value?.id) {
      filterObj.employee_id = values.subordinate.value.id;
    }
    if (values.type) filterObj.type = values.type;
    if (values.type && Array.isArray(values.statuses) && values.statuses.length) {
      filterObj.statuses = values.statuses.map((statusOption) => statusOption.value);
    }
    setIsLoading(true);
    await getTasks(filterObj);
    setIsLoading(false);
  };

  const initialCall = useRef(false);

  useEffect(() => {
    if (initialCall.current) return;
    initialCall.current = true;
    handleSubmit(submitRequest)();
  }, [getTasks, handleSubmit]);

  return (
    <Button
      color="green"
      position="left"
      size={20}
      disabled={isLoading || !isValid}
      onClick={handleSubmit(submitRequest)}
    >
      Показать
    </Button>
  );
}
