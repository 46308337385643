import { object } from 'prop-types';
import { Controller, useFieldArray } from 'react-hook-form';
import Tag from '@shared/components/Tag/Tag';

import { TagsWrapper } from './style';

SelectableTagsList.propTypes = {
  control: object,
};

export function SelectableTagsList({ control }) {
  const { fields } = useFieldArray({ control, name: 'tags' });

  return (
    <TagsWrapper>
      {fields.map((field, index) => (
        <Controller
          key={field.id}
          control={control}
          name={`tags.${index}`}
          render={({ field }) => {
            const isActive = field.value.selected ? 'tag--active' : '';
            return (
              <Tag
                tag={field.value}
                $active={isActive}
                onClick={() => field.onChange({ ...field.value, selected: !isActive })}
              />
            );
          }}
        />
      ))}
    </TagsWrapper>
  );
}
