import { Controller, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { useEffect, useMemo } from 'react';

import { DropdownIndicator, MultiValue, colorStyles } from './lib';
import { StyledWrapper } from './style';

export const rubricOptions = [
  { label: 'В работе', value: 'in_progress' },
  { label: 'Завершена', value: 'finished' },
];

export const requestOptions = [
  { label: 'Поставлена', value: 'created' },
  { label: 'На уточнении', value: 'On_clarification' },
  { label: 'В работе', value: 'In_progress' },
  { label: 'На проверке', value: 'under_review' },
  { label: 'Завершена', value: 'finished' },
  { label: 'Отменена', value: 'canceled' },
];

export function TaskStatusSelect({ control, setValue }) {
  const taskType = useWatch({ control, name: 'type' });

  useEffect(() => {
    if (taskType === 'rubric') {
      setValue('statuses', rubricOptions);
    } else if (taskType === 'request') {
      setValue('statuses', requestOptions);
    }
  }, [setValue, taskType]);

  const { options, placeholderText } = useMemo(() => {
    let options = [];
    let placeholderText = '';
    if (taskType === 'rubric') {
      options = rubricOptions;
      placeholderText = 'выберите статусы разделов';
    } else if (taskType === 'request') {
      options = requestOptions;
      placeholderText = 'выберите статусы просьб';
    }
    return { options, placeholderText };
  }, [taskType]);

  return (
    <>
      {taskType && (
        <StyledWrapper>
          <Controller
            name="statuses"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <Select
                  isMulti
                  components={{
                    MultiValue,
                    DropdownIndicator,
                  }}
                  styles={colorStyles()}
                  options={options}
                  placeholder={placeholderText}
                  onChange={(value) => onChange(value)}
                  value={value}
                  noOptionsMessage={() => 'Нет опций'}
                />
              );
            }}
          />
        </StyledWrapper>
      )}
    </>
  );
}
