import { useState } from 'react';
import { object, number, string, arrayOf } from 'prop-types';
import { Label } from '@shared/components';

import { Wrapper, DropdownInput } from './styles';
import DropdownPanel from './DropdownPanel';

DropdownContainer.propTypes = {
  structureId: number,
  structure: arrayOf(object),
  structureTitle: string,
  selectedStructureItem: object,
  dropdownLabel: string,
};

function DropdownContainer({ structure, selectedStructureItem, dropdownLabel, structureId, structureTitle }) {
  const [isExpanded, setIsExpanded] = useState();

  return (
    <div>
      <Label>{dropdownLabel}</Label>

      <Wrapper onClick={() => setIsExpanded(!isExpanded)}>
        <DropdownInput $show={isExpanded}>
          {selectedStructureItem?.path?.reduce((prev, curr, index) => {
            let pathString = prev;
            pathString += Object.keys(curr)[0];
            if (selectedStructureItem.path.length - 1 !== index) {
              pathString += ' / ';
            }
            return pathString;
          }, '')}
        </DropdownInput>

        <DropdownPanel
          show={isExpanded}
          structureId={structureId}
          structure={structure}
          structureTitle={structureTitle}
        />
      </Wrapper>
    </div>
  );
}

export default DropdownContainer;
