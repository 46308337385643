import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { func, object, number, string } from 'prop-types';
import Preloader from '@components/preloaders/Preloader';
import SnackbarContext from 'src/contexts/SnackbarContext';

import DropdownContainer from './DropdownContainer';
import { StructureDropdownContextProvider } from './StructureDropdownContext';

StructureDropdown.propTypes = {
  /** id структуры. Передается в качестве параметра в функцию getStructure */
  structureId: number,
  /** title текущей структуры */
  structureTitle: string,
  /** Выбранный элемент структуры */
  selectedStructureItem: object,
  /** Функция для передачи выбираемого элемента в структуре */
  setSelectedStructureItem: func,
  /** Функция для получения данных по структуре. Сюда передается structureId в качестве параметра функции */
  getStructure: func,
  /** Название этого dropdown */
  dropdownLabel: string,
  /** Текст показываемого сообщения, на случай если функция getStructure возвращает ошибку */
  errorMsg: string,
  /** sublistKey - название поля объекта, где располагается ветка структуры */
  sublistKey: string,
  /** иконка элемента структуры */
  structureItemIcon: string,
  /** Опциональный prop. Функция должна возвращать string со стилями. В качестве параметров, в функцию могут передаваться статусы $active, $hasSublist */
  itemLabelCustomStylesFunc: func,
};

function StructureDropdown({
  structureId,
  structureTitle,
  getStructure,
  selectedStructureItem,
  setSelectedStructureItem,
  dropdownLabel,
  errorMsg,
  sublistKey,
  structureItemIcon,
  itemLabelCustomStylesFunc,
  isInitialParent,
}) {
  const [isLoadingStructure, setIsLoadingStructure] = useState(false);
  const [structure, setStructure] = useState(null);

  const { showSnackbar } = useContext(SnackbarContext);

  // получить данные по структуре
  useEffect(() => {
    if (!structureId || typeof getStructure !== 'function') return;
    setIsLoadingStructure(true);
    (async () => {
      try {
        const structure = await getStructure(structureId);
        if (!structure) return;
        setStructure(structure);
      } catch {
        showSnackbar(errorMsg);
      } finally {
        setIsLoadingStructure(false);
      }
    })();
  }, [getStructure, structureId]);

  const handleSelect = useCallback(
    (structureItemData, structureItemPath) => {
      setSelectedStructureItem({ id: structureItemData.id, path: structureItemPath });
    },
    [setSelectedStructureItem],
  );

  const contextValues = useMemo(
    () => ({
      structureItemIcon,
      itemLabelCustomStylesFunc,
      handleSelect,
      sublistKey,
      selectedStructureItem,
      isInitialParent,
    }),
    [structureItemIcon, itemLabelCustomStylesFunc, handleSelect, sublistKey, selectedStructureItem],
  );

  return (
    <StructureDropdownContextProvider value={contextValues}>
      {isLoadingStructure ? (
        <Preloader />
      ) : (
        <>
          {structure && (
            <DropdownContainer
              structure={structure}
              selectedStructureItem={selectedStructureItem}
              dropdownLabel={dropdownLabel}
              structureId={structureId}
              structureTitle={structureTitle}
              structureItemIcon={structureItemIcon}
            />
          )}
        </>
      )}
    </StructureDropdownContextProvider>
  );
}

export default StructureDropdown;
