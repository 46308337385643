import { memo, useCallback, useContext, useState } from 'react';
import { func, string } from 'prop-types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SnackbarContext from 'src/contexts/SnackbarContext';

import { StyledChatMenuItem } from '../styles';
import ProjectTransferModal from '../project-transfer/ProjectTransfer';

MenuItemChangeParentFC.propTypes = {
  toggleOptionsMenu: func,
  taskId: string,
  projectId: string,
};

function MenuItemChangeParentFC({ toggleOptionsMenu, taskId, projectId, parent_task_id }) {
  const [transferModal, setTransferModal] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const handleTransfer = useCallback(
    async (data) => {
      const submitData = {};
      if (data.selectedTaskId && data.selectedTaskId !== 'root') {
        submitData.parent_task_id = data.selectedTaskId;
      } else if (data.selectedProjectId) submitData.parent_project_id = data.selectedProjectId;

      let taskIdToNavigate, messageText;
      if (taskId) {
        await axios.patch('/api/task_to_task', {
          ...submitData,
          task_id: +taskId,
        });
        taskIdToNavigate = taskId;
        messageText = 'Задача перенесена';
      } else {
        const response = await axios.patch('/api/project_to_task', {
          ...submitData,
          project_id: +projectId,
        });
        taskIdToNavigate = response.data.result;
        messageText = 'Проект перенесен';
      }
      setTransferModal(false);
      showSnackbar(messageText, 'success');
      navigate(`/projects/${data.selectedProjectId}/tasks/${taskIdToNavigate}`);
    },
    [projectId, taskId],
  );

  return (
    <>
      <StyledChatMenuItem
        onClick={() => {
          toggleOptionsMenu(false);
          setTransferModal(true);
        }}
      >
        Сменить родителя
      </StyledChatMenuItem>

      {transferModal && (
        <ProjectTransferModal
          close={() => setTransferModal(false)}
          submitRequest={handleTransfer}
          currentProjectId={projectId}
          currentTaskId={taskId}
          parent_task_id={parent_task_id}
          hideCurrentProject={!taskId} //не показывать текущий проект в селекте, если переносится проект
        />
      )}
    </>
  );
}

const MenuItemChangeParent = memo(MenuItemChangeParentFC);

export default MenuItemChangeParent;
