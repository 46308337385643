import { bool, arrayOf, shape } from 'prop-types';
import Preloader from 'src/components/preloaders/Preloader';
import { ProjectCardList } from 'src/pages/Projects/project-list/project-list-container/style';

import TaskListItem from './TaskListItem';
import { TaskItemPropType } from './lib';

TaskList.propTypes = {
  isLoading: bool,
  tasksList: arrayOf(shape(TaskItemPropType)),
};

function TaskList({ isLoading, tasksList }) {
  return (
    <>
      {isLoading && <Preloader />}

      {!isLoading && tasksList && (
        <ProjectCardList>
          {tasksList.map((el, i) => (
            <TaskListItem task={el} key={`${el.id}_${i}`} />
          ))}
        </ProjectCardList>
      )}
    </>
  );
}

export default TaskList;
