import Preloader from '@components/preloaders/Preloader';
import axios from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import SnackbarContext from 'src/contexts/SnackbarContext';

import { StyledIframe, StyledIframeWrapper } from './styles';

export function ExcelViewer({ url }) {
  const iframeRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    if (url) {
      setIsLoading(true);
      axios
        .get(url)
        .then((response) => {
          let htmlString = response.data;
          if (typeof response.data === 'string') {
            htmlString = htmlString.replaceAll('HREF="#', 'HREF="about:srcdoc#');
          }

          iframeRef.current.srcdoc = htmlString;
        })
        .catch(() => showSnackbar('Не удалось отобразить файл'))
        .finally(() => setIsLoading(false));
    }
  }, [url]);

  return (
    <div>
      {isLoading && <Preloader />}
      <StyledIframeWrapper>
        <StyledIframe ref={iframeRef} title="excel-preview"></StyledIframe>
      </StyledIframeWrapper>
    </div>
  );
}
