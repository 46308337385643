import { useCallback, useContext, useState } from 'react';
import axios from 'axios';
import { Title } from '@shared/components';
import SnackbarContext from 'src/contexts/SnackbarContext';

import TaskFilters from './TaskFilters/TaskFilters';
import TaskList from './TaskList';
import { TasksContainer } from './style';

function Tasks() {
  const [isLoading, setIsLoading] = useState(false);
  const [tasksList, setTasksList] = useState(null);

  const { showSnackbar } = useContext(SnackbarContext);

  const getTasks = useCallback(async (filterObj) => {
    setIsLoading(true);
    await axios
      .patch('/api/task_filter', filterObj)
      .then((res) => {
        const tasks = res.data.tasks.tasks;
        if (Array.isArray(res.data.tasks?.tasks)) {
          tasks.sort((a, b) => {
            return new Date(a.date_created) < new Date(b.date_created);
          });
          setTasksList(tasks);
        }
      })
      .catch(() => showSnackbar('Возникла ошибка при получении списка задач'))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <main>
      <TasksContainer>
        <Title>Мои задачи</Title>
        <TaskFilters getTasks={getTasks} />
        <TaskList isLoading={isLoading} tasksList={tasksList} />
      </TasksContainer>
    </main>
  );
}

export default Tasks;
