import { createContext, useContext } from 'react';
import { object } from 'prop-types';

const StructureDropdownContext = createContext({
  structureItemIcon: '',
  sublistKey: '',
  itemLabelCustomStylesFunc: () => '',
  handleSelect: () => ({}),
  selectedStructureItem: null,
  isInitialParent: null,
});

StructureDropdownContextProvider.propTypes = {
  children: object,
  value: object,
};

function StructureDropdownContextProvider({ children, value }) {
  return <StructureDropdownContext.Provider value={value}>{children}</StructureDropdownContext.Provider>;
}

const useStructureDropdownContext = () => {
  const context = useContext(StructureDropdownContext);
  if (context) {
    return context;
  }
  throw new Error('useStructureDropdownContext must be used within a StructureDropdownContext');
};

export { useStructureDropdownContext, StructureDropdownContextProvider };
