import { Button } from '@shared/components/Button/Button';
import ModalPortal from '@shared/components/Modal/ModalPortal';

import {
  StyledCloseModalButton,
  StyledModal,
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
  StyledModalHeaderButton,
  StyledModalHeaderButtonWrapper,
  StyledModalInner,
  StyledModalTitle,
  StyledModalWrapper,
} from './style';

// чтобы добавить кнопки в шапку модалки, нужно создать массив и передать его в headerButtons
// const headerButtons = [
//   {name: 'Ред.', action: () => {}},
//   {name: 'Удалить', action: () => {}}
// ]

export const Modal = ({
  type,
  modalSize,
  onSave,
  onClose,
  title,
  headerButtons,
  withoutButtons,
  confirmButtonText,
  cancelButtonText,
  disabledSaveButton,
  footerContent,
  children,
  noModalPadding,
  customModalWrapperPadding,
}) => {
  return (
    <ModalPortal>
      <StyledModal>
        <StyledModalWrapper $modalWrapperPadding={customModalWrapperPadding}>
          <StyledModalInner modalsize={modalSize}>
            <StyledModalBody $noPadding={noModalPadding}>
              <StyledModalHeader $noPadding={noModalPadding}>
                <StyledModalTitle size={3} as="h3">
                  {title}
                </StyledModalTitle>

                <StyledModalHeaderButtonWrapper>
                  {headerButtons &&
                    headerButtons.map((button, index) => {
                      return (
                        <StyledModalHeaderButton key={index} onClick={button.action}>
                          {button.name}
                        </StyledModalHeaderButton>
                      );
                    })}
                </StyledModalHeaderButtonWrapper>

                <StyledCloseModalButton onClick={onClose} $buttonMargin={noModalPadding} />
              </StyledModalHeader>

              {children}

              <StyledModalFooter>
                {!withoutButtons && (
                  <>
                    <Button
                      minWidth={'153px'}
                      color={type === 'delete' ? 'red' : 'green'}
                      onClick={onSave}
                      disabled={disabledSaveButton}
                    >
                      {confirmButtonText ? confirmButtonText : 'Подтвердить'}
                    </Button>
                    <Button width={'153px'} color={'light'} onClick={onClose}>
                      {cancelButtonText ? cancelButtonText : 'Отмена'}
                    </Button>
                  </>
                )}
              </StyledModalFooter>
              {footerContent && <div>{footerContent}</div>}
            </StyledModalBody>
          </StyledModalInner>
        </StyledModalWrapper>
      </StyledModal>
    </ModalPortal>
  );
};
