import React from 'react';

import { Button, Modal } from '@shared/components';

import { isFileNameImage, isPreviewFile } from 'src/utilize/helper-functions';

import iconDownload from '@assets/images/icons/icon-download.svg';
import { useDownloader } from '@shared/hooks/useDownloader';

import { Wrapper, StyledTitleContainer } from './styles';
import { ImagesViewer } from './ui/ImagesViewer';
import { DocsViewer } from './ui/DocsViewer';

export const PreviewModal = ({ url, filePath, fileName, onClose, libType }) => {
  const { downloadFile } = useDownloader();

  return (
    <Modal
      modalSize={'1200px'}
      onClose={onClose}
      withoutButtons
      noModalPadding
      customModalWrapperPadding="15px"
      title={
        <StyledTitleContainer gap="20px" align="center">
          {fileName}{' '}
          <Button onClick={() => downloadFile({ url: filePath, filename: fileName, forceDownload: true })}>
            <img src={iconDownload} alt="скачать" />
          </Button>
        </StyledTitleContainer>
      }
    >
      <Wrapper>
        {isFileNameImage(fileName) && <ImagesViewer url={url} filePath={filePath} fileName={fileName} />}
        {isPreviewFile(fileName) && <DocsViewer filePath={filePath} fileName={fileName} libType={libType} />}
      </Wrapper>
    </Modal>
  );
};
