import { css, styled } from 'styled-components';
import { Link } from 'react-router-dom';

export const ProjectCardList = styled.section`
  gap: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  margin-top: 40px;
`;

export const ProjectCard = styled.section`
  background-color: ${({ theme }) => theme.light};
  border: 1px solid ${({ theme }) => theme.borderSecondary};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  position: relative;
  transition: transform 0.2s ease-in-out;
`;

export const ProjectCardHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;
export const ProjectCardDate = styled.span`
  font-size: 12px;
  line-height: 20px;
`;

export const ProjectCardStatus = styled.span`
  align-items: center;
  display: flex;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 2px;
  padding: 1px 28px 1px 8px;
  position: relative;
  width: max-content;

  &::after {
    border-radius: 50%;
    content: '';
    height: 12px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;

    ${(props) =>
      props.color === 'grey' &&
      css`
        background-color: ${({ theme }) => theme.disabled};
      `}

    ${(props) =>
      props.color === 'red' &&
      css`
        background-color: ${({ theme }) => theme.warning};
      `}

    ${(props) =>
      props.color === 'green' &&
      css`
        background-color: ${({ theme }) => theme.success};
      `}

    ${(props) =>
      props.color === 'yellow' &&
      css`
        background-color: ${({ theme }) => theme.warningSecondary};
      `}
  }
`;

export const ProjectIconNotify = styled.span`
  filter: brightness(0) saturate(100%) invert(10%) sepia(12%) saturate(7%) hue-rotate(339deg) brightness(104%)
    contrast(88%);
  height: 14px;
  margin: 0 10px;
  width: 11px;
  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const ProjectCardTitle = styled.p`
  font-weight: 600;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.main};
  }
`;

export const ProjectCardDescription = styled.p`
  strong,
  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }
`;

export const ProjectCardUser = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
`;

export const ProjectCardUsername = styled.p`
  font-weight: 600;
`;

export const ProjectCardTags = styled.p`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const ProjectCardLink = styled(Link)`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;
