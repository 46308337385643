import DocViewer from '@cyntler/react-doc-viewer';
import { Container } from '@shared/components/Container';
import styled from 'styled-components';

export const StyledContainer = styled(Container)`
  flex-direction: column;
`;

export const DocViewerHeader = styled(Container)`
  padding: 14px 24px;
  align-items: center;
  gap: 4px 36px;
  background-color: #f2f9ff;
  justify-content: space-between;
  width: 100%;

  button {
    flex: 0 0 36px;
    border-radius: 50% !important;
    padding: 0;
    width: 36px;
    height: 36px;
    border-width: 2px;
    &:hover {
      background-color: #fff;

      /* border-color: #fff; */

      & img {
        filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
      }
    }

    & img {
      width: 60%;
      filter: invert(100%);
    }
  }

  @media (max-width: 700px) {
    button {
      flex: 0 0 30px;
      width: 30px;
      height: 30px;
    }
  }
`;

export const PluginContainer = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 12px;

  min-height: 60vh;

  margin-top: -18px;

  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);

  #pdf-download,
  #file-name,
  #header-bar,
  #pdf-toggle-pagination {
    display: none;
  }

  #pdf-controls {
    padding-right: 30px;
  }

  #pdf-controls,
  #pdf-zoom-out,
  #pdf-zoom-in,
  #pdf-zoom-reset,
  #pdf-pagination-next,
  #pdf-pagination-prev {
    background-color: transparent;
    box-shadow: none;
  }

  #pdf-zoom-out:hover,
  #pdf-zoom-in:hover,
  #pdf-zoom-reset:hover,
  #pdf-pagination-next:hover,
  #pdf-pagination-prev:hover {
    filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
  }

  @media (min-width: 700px) {
    #pdf-zoom-out,
    #pdf-zoom-in,
    #pdf-zoom-reset {
      display: none;
    }
  }
`;

export const StyledDocViewer = styled(DocViewer)`
  & #pdf-toggle-pagination {
    display: none;
  }
`;
