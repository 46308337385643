import { Container } from '@shared/components/Container';
import styled from 'styled-components';

export const Wrapper = styled(Container)`
  justify-content: center;
`;

export const StyledTitleContainer = styled(Container)`
  padding-top: 20px;
  padding-left: 20px;

  & button {
    flex: 0 0 36px;
    border-radius: 50% !important;
    padding: 0;
    width: 36px;
    height: 36px;
    border-width: 2px;
    &:hover {
      background-color: #fff;

      /* border-color: #fff; */

      & img {
        filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
      }
    }

    & img {
      width: 60%;
      filter: invert(100%);
    }
  }
`;
