import axios from 'axios';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '@shared/components';
import FileToUpload from '@components/files/FileToUpload';
import { UploadFilesWrapper } from '@components/storage/styles';

import { randomSequence, useEventTriggerOnEscPress } from 'src/utilize/helper-functions';
import SnackbarContext from 'src/contexts/SnackbarContext';
import ConfirmAction from 'src/components/warnings/ConfirmAction';
import Dropzone from 'src/shared/components/Dropzone/Dropzone';

import { getStorageTree } from 'src/redux/features/storageSlice';

import ReplaceFilesConfirmModal from '../ReplaceFilesConfirmModal';

import { useResumable } from './useResumable';
import { getQueryData } from './lib';

export const AddFilesModalV2 = ({ close, storageIdToReq, folderId }) => {
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState();
  const [confirmClose, setConfirmClose] = useState();
  const [confirmFilesReplacement, setConfirmFilesReplacement] = useState();

  const { showSnackbar } = useContext(SnackbarContext);

  const dispatch = useDispatch();

  const [resumable] = useState(
    useResumable({
      folderId: folderId,
      storageId: storageIdToReq,
      setFilesToUpload,
      onClose: close,
    }),
  );

  const hasCompletedUploads = () => {
    return filesToUpload.some((fileData) => fileData.status === 'done');
  };

  const handleClose = () => {
    if (filesToUpload.length) return setConfirmClose(true);
    close();
  };

  useEventTriggerOnEscPress(handleClose);

  const handleFilesAdd = useCallback((filesArr) => {
    if (!filesArr?.length) return;
    setFilesToUpload((f) =>
      [...f, ...filesArr].map((file) => {
        if (file.localId) return file;
        return {
          file: file,
          progress: 0,
          localId: randomSequence(),
          status: 'wait',
        };
      }),
    );
  }, []);

  const handleFilesSelect = useCallback((e) => handleFilesAdd(e.target.files), [handleFilesAdd]);

  const handleDrop = useCallback(
    async (e) => {
      e.preventDefault();
      if (isSubmitting) return;
      if (e.dataTransfer.files.length) {
        const items = e.dataTransfer.items;
        // проверить поддерживается ли браузером функция webkitGetAsEntry
        if (typeof items[0].webkitGetAsEntry !== 'function') {
          handleFilesAdd(e.dataTransfer.files);
        } else {
          const filesToDrop = [];
          const filePromises = [];
          for (let i = 0; i < items.length; i++) {
            const entry = items[i].webkitGetAsEntry();
            if (!entry?.isFile) continue;
            const filePromise = new Promise((resolve, reject) => {
              entry.file(
                (file) => {
                  filesToDrop.push(file);
                  resolve();
                },
                (e) => reject(e),
              );
            });
            filePromise.catch(() => {
              showSnackbar('Возникла ошибка при чтении файла');
            });
            filePromises.push(filePromise);
          }

          await Promise.all(filePromises);

          handleFilesAdd(filesToDrop);
        }
      }
    },
    [isSubmitting, handleFilesAdd, showSnackbar],
  );

  const submitFiles = () => {
    resumable.upload();
  };

  // проверка наличия файлов с таким названием в этой папке на сервере
  const checkFilesExistence = async () => {
    const reqBody = {
      folder_id: folderId ? folderId : null,
      storage_id: folderId ? null : storageIdToReq,
      files: filesToUpload.map((fileData) => fileData.file.name),
    };

    setIsSubmitting(true);

    await axios
      .post('/api/storage_file_exist', reqBody)
      .then((r) => {
        const { storage_files_exist } = r.data;
        if (storage_files_exist.length) {
          return setConfirmFilesReplacement(storage_files_exist);
        }

        submitFiles();
      })
      .catch(() => {
        showSnackbar('Ошибка при проверке наличия таких файлов');
        setIsSubmitting(false);
      });
  };

  const removeFileHandler = async ({ fileData, index }) => {
    setFilesToUpload(filesToUpload.filter((file, ind) => ind !== index));

    const queryData = getQueryData({ storageId: storageIdToReq, folderId });

    const currentFile = resumable.getFromUniqueIdentifier(fileData.file.uniqueIdentifier);

    try {
      if (currentFile) {
        currentFile.cancel();
      }

      if (fileData.status === 'done') {
        axios.patch('/api/storage_files/remove', {
          files: fileData.localId,
        });
      }

      if (fileData.status === 'loading') {
        await axios.delete('/api/storage_file/cancel', {
          data: {
            uniqueIdentifier: currentFile.uniqueIdentifier,
            ...queryData,
          },
        });
      }
    } catch (e) {
      showSnackbar('Возникла ошибка при удалении файла', 'error');
    }
  };

  const removeFilesHandler = async () => {
    const uploadedFiles = filesToUpload.filter((fileData) => fileData.status === 'done');
    const loadingFiles = filesToUpload.filter((fileData) => fileData.status === 'loading');

    try {
      if (filesToUpload.length) {
        resumable.cancel();

        setFilesToUpload([]);
      }

      if (uploadedFiles.length) {
        axios.patch('/api/storage_files/remove', {
          files: uploadedFiles.map((file) => file.localId),
        });
      }

      if (loadingFiles.length) {
        const queryData = getQueryData({ storageId: storageIdToReq, folderId });

        for (const loadingFile of loadingFiles) {
          await axios.delete('/api/storage_file/cancel', {
            data: {
              uniqueIdentifier: loadingFile.file.uniqueIdentifier,
              ...queryData,
            },
          });
        }
      }
    } catch (e) {
      showSnackbar('Возникла ошибка при удалении файлов', 'error');
    }
  };

  const dropzoneDescription = useRef(
    <>
      Перетяните файлы
      <br />
      или кликните, чтобы выбрать
    </>,
  );

  useEffect(() => {
    if (filesToUpload.length) {
      filesToUpload.forEach(({ file }) => {
        if (!!resumable.getFromUniqueIdentifier(file.uniqueIdentifier)) {
        } else {
          resumable.addFile(file);
        }
      });
    }
  }, [filesToUpload]);

  return (
    <>
      <Modal
        title="Добавить файлы"
        onClose={handleClose}
        onSave={checkFilesExistence}
        disabledSaveButton={!filesToUpload.length || isSubmitting}
        confirmButtonText="Добавить"
      >
        <div data-popup="storageModalPopup"></div>
        <UploadFilesWrapper>
          {filesToUpload.map((fileData, i) => (
            <FileToUpload
              file={fileData.file}
              progressPercent={fileData.progress}
              isUploaded
              removeFile={() => removeFileHandler({ fileData, index: i })}
              key={i}
              index={i}
            />
          ))}
        </UploadFilesWrapper>

        <Dropzone
          onDrop={handleDrop}
          handleFilesSelect={handleFilesSelect}
          description={dropzoneDescription.current}
          inputMultipleSelect
        />
      </Modal>

      {confirmClose && (
        <ConfirmAction
          confirm={() => {
            removeFilesHandler();
            if (hasCompletedUploads()) {
              dispatch(getStorageTree({ storageId: storageIdToReq, showSnackbar }));
            }
            close();
          }}
          cancel={() => setConfirmClose(false)}
          actionText="Вы уверены, что хотите закрыть окно не сохранив изменения?"
        />
      )}

      {confirmFilesReplacement && (
        <ReplaceFilesConfirmModal
          filesToReplace={confirmFilesReplacement}
          close={() => {
            setConfirmFilesReplacement(null);
            setIsSubmitting(false);
          }}
          confirm={() => {
            submitFiles();
            setConfirmFilesReplacement(null);
          }}
        />
      )}
    </>
  );
};
