import { useCallback } from 'react';
import { useSocketContext } from 'src/contexts/SocketContext';
import { rtkQueryApi } from 'src/redux/features/api/rtkQueryApi';
import { useDispatch } from 'react-redux';

import useAuth from './useAuth';

const useLogout = () => {
  const auth = useAuth();
  const { deinitSocket } = useSocketContext();
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    if (auth?.logOut) auth.logOut();
    if (deinitSocket) deinitSocket();
    dispatch(rtkQueryApi.util.resetApiState());
  }, [auth?.logOut, deinitSocket]);

  return logout;
};

export default useLogout;
