import { IconButton } from '@shared/components';
import { components } from 'react-select';
import iconAdd from '@assets/images/icons/plus.svg';

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconButton icon={iconAdd} size={20} background={'#52c41a'} />
    </components.DropdownIndicator>
  );
};

export const MultiValue = (props) => {
  return <components.MultiValue {...props}>{props.children}</components.MultiValue>;
};

export const colorStyles = (provided) => ({
  ...provided,
  container: (styles) => ({
    ...styles,
    width: 'fit-content',
    // minWidth: '200px',
    maxWidth: '100%',
  }),
  control: (styles) => ({
    ...styles,
    borderWidth: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row-reverse',
    width: 'fit-content',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorContainer: () => ({
    padding: 0,
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    alignItems: 'center',
    overflowX: 'auto',
    maxWidth: '100%',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  }),
  multiValueLabel: () => {
    return {
      position: 'relative',
      width: '100%',
    };
  },
  multiValue: () => {
    return {
      alignItems: 'center',
      display: 'flex',
      whiteSpace: 'nowrap',
      border: '1px solid #d9d9d9',
      borderRadius: '2px',
      padding: '1px 8px',
      fontSize: '12px',
      lineHeight: '20px',
      backgroundColor: 'transparent',
    };
  },
  // кнопка удаления
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#d9d9d9',
    borderRadius: '100px',
    border: '1px solid #d9d9d9',
    padding: '0px',
    marginLeft: '12px',
    height: '14px',
    width: '14px',
    ':hover': {
      color: 'red',
      border: '1px solid red',
    },
  }),
});
