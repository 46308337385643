import { func } from 'prop-types';
import { useState, memo, useEffect } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import { Container, IconButton } from '@shared/components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, date, object, string, number, boolean } from 'yup';

import iconFilter from '@assets/images/icons/filter.svg';

import { GetTasksButton } from './GetTasksButton';
import { SelectableTagsList } from './SelectableTagsList';
import { SubordinateSelect } from './SubordinateSelect';
import { StyledRelationSelectWrapper, StyledSelectsContainer } from './style';
import TaskTypeRadioSelect from './TaskTypeRadioSelect';
import { TaskStatusSelect } from './TaskStatusSelect';
import { DropdownIndicator, MultiValue, colorStyles } from './lib';

const options = [
  { label: 'Автор', value: 'creator' },
  { label: 'Ответственный', value: 'responsible' },
  { label: 'Исполнитель', value: 'executor' },
  { label: 'Участник', value: 'member' },
];

const formSchema = object({
  deadline_end: date(),
  relation: array()
    .of(object({ label: string(), value: string() }))
    .required()
    .min(1),
  tags: array()
    .of(object({ tagId: number(), selected: boolean() }))
    .nullable(),
});

TaskFiltersFC.propTypes = {
  getTasks: func,
};

function TaskFiltersFC({ getTasks }) {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      relation: options,
      tags: [],
      subordinate: { label: 'Я', value: { id: '' } },
    },
  });

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const tagsList = useSelector((state) => state.tags.tagsList);

  // пришлось убрать makeAnimated, он мешал overflow
  // const animatedComponents = makeAnimated({
  //   MultiValue,
  //   DropdownIndicator,
  // });

  useEffect(() => {
    if (tagsList?.length && reset) {
      reset({ tags: tagsList.map((tag) => ({ ...tag, tagId: tag.id, selected: false })) });
    }
  }, [tagsList, reset]);

  return (
    <>
      <Container justify="space-between" align="center" wrap="wrap" gap="12px">
        <StyledSelectsContainer align="center" wrap="wrap" gap="12px">
          <SubordinateSelect control={control} />

          <StyledRelationSelectWrapper>
            <Controller
              name="relation"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Select
                    isMulti
                    components={{
                      MultiValue,
                      DropdownIndicator,
                    }}
                    styles={colorStyles()}
                    options={options}
                    placeholder="выберите отношение к задачам"
                    onChange={(value) => onChange(value)}
                    value={value}
                    noOptionsMessage={() => 'Нет опций'}
                  />
                );
              }}
            />
          </StyledRelationSelectWrapper>
        </StyledSelectsContainer>
        <Container align="center" gap="12px">
          <IconButton
            style={{ backgroundSize: '24px' }}
            size={40}
            background="#1890FF"
            icon={iconFilter}
            onClick={() => setIsFiltersOpen((isFiltersOpen) => !isFiltersOpen)}
          />
          <GetTasksButton getTasks={getTasks} isValid={isValid} handleSubmit={handleSubmit} />
        </Container>
      </Container>
      {isFiltersOpen && (
        <div>
          <TaskTypeRadioSelect control={control} />
          <TaskStatusSelect control={control} setValue={setValue} />
          <SelectableTagsList control={control} />
        </div>
      )}
    </>
  );
}

const TaskFilters = memo(TaskFiltersFC);

export default TaskFilters;
