import styled from 'styled-components';

export const StyledIframeWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 76vh;

  @media (max-height: 640px) {
    height: 68vh;
  }

  @media (max-height: 560px) {
    height: 64vh;
  }

  @media (max-height: 500px) {
    height: 60vh;
  }

  @media (max-height: 460px) {
    height: 55vh;
  }

  @media (max-height: 430px) {
    height: 50vh;
  }

  @media (max-height: 380px) {
    height: 45vh;
  }
`;

export const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;
