import { useContext, useEffect, useState } from 'react';
import { func, object } from 'prop-types';

import Preloader from '@components/preloaders/Preloader';
import { Label, SingleSelect } from '@shared/components';

import SnackbarContext from 'src/contexts/SnackbarContext';

ProjectSelect.propTypes = {
  setSelectedProjectOption: func,
  getProjectOptions: func,
  defaultValue: object,
};

function ProjectSelect({ setSelectedProjectOption, getProjectOptions, defaultValue, currentProjectId }) {
  const { showSnackbar } = useContext(SnackbarContext);
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);
  const [projectsOptions, setProjectsOptions] = useState([]);

  useEffect(() => {
    if (!getProjectOptions || typeof getProjectOptions !== 'function') return;
    (async () => {
      try {
        const projectOptions = await getProjectOptions();
        if (!Array.isArray(projectOptions)) return;

        const initialProjectOption = projectOptions.find((option) => option.value === +currentProjectId);

        if (initialProjectOption) {
          setSelectedProjectOption(initialProjectOption);
        }
        setProjectsOptions(projectOptions);
      } catch {
        showSnackbar('Возникла ошибка при получении списка проектов');
      } finally {
        setIsLoadingProjects(false);
      }
    })();
  }, [getProjectOptions, currentProjectId]);

  return (
    <>
      {isLoadingProjects ? (
        <Preloader />
      ) : (
        <div>
          <Label>Проект</Label>
          <SingleSelect
            options={projectsOptions}
            defaultValue={defaultValue}
            onChange={(option) => setSelectedProjectOption(option)}
            placeholder="Выбрать проект"
          />
        </div>
      )}
    </>
  );
}

export default ProjectSelect;
