import { Container, IconButton } from '@shared/components';
import styled from 'styled-components';

export const StyledDocContainer = styled.div`
  width: 100%;
  height: 85%;
  transform: scale(${({ $scale }) => $scale});
  transform-origin: top center;

  @media (max-width: 1100px) {
    .docx-wrapper {
      padding: 0;
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  &:disabled {
    cursor: not-allowed;
    filter: invert(0.5);
  }

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const StyledButtonsContainer = styled(Container)`
  padding: 10px;
  padding-right: 30px;
  /* margin-bottom: 10px; */
`;
