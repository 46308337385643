import styled from 'styled-components';
import { Container } from '@shared/components';

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 36px;
  margin-top: 10px;
`;

export const StyledRelationSelectWrapper = styled.div`
  width: 530px;
  max-width: 100%;
  display: flex;
`;

export const StyledSelectsContainer = styled(Container)`
  max-width: 100%;
`;

export const StyledEmployeeSelectWrapper = styled.div`
  width: 250px;
  max-width: 100%;
`;

export const StyledWrapper = styled.div`
  margin: 20px 0;
`;
