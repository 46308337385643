// для тестирования настоящими юзерами
// export const server_url = 'http://80.78.248.55:3213';
// export const files_url = 'http://portal.starkandbau.ru/stats';
// export const downloadFileUrl = `${server_url}/api/download_file/${encodeURIComponent('stats/')}`;
// export const generatePdfUrl = `${server_url}/api/generate_pdf/stats`;

// export const server_url = 'http://80.78.248.55:88';
// export const files_url = 'http://portal-test.starkandbau.ru/stat_files';
export const isDevServer = process.env.NODE_ENV === 'development';
export const staticFilesPathPart = 'stat_files';

// development сервер
export const server_url = 'https://portal-test.starkandbau.ru:88';
export const files_url = 'https://portal-test.starkandbau.ru:88/stat_files';

export const downloadFileUrl = `${server_url}/api/download_file/stat_files`;

export const getFileSizeUrl = `${server_url}/api/file_size/stat_files`;

export const generatePdfUrl = `${server_url}/api/generate_pdf/stat_files`;
export const generateExcelHtmlUrl = `${server_url}/api/generate_html/stat_files`;

// ОТДЕЛЫ
export const url_departments_add = `${server_url}/api/departments/add`;
export const url_get_departments_list = `${server_url}/api/departments_list`;
// строим окно редактирования отдела
export const url_get_department_edit = `${server_url}/api/department_edit`;
export const url_put_departments_edit = `${server_url}/api/departments/edit`;
export const url_get_departments_create = `${server_url}/api/department_create`;
export const url_delete_departments = `${server_url}/api/departments/delete`;
//  ДОЛЖНОСТИ
export const url_get_positions_list = `${server_url}/api/positions_list`;
export const url_post_new_positions = `${server_url}/api/positions/add`;
export const url_put_positions = `${server_url}/api/positions/edit`;
export const url_delete_positions = `${server_url}/api/positions/delete`;
// СОТРУДНИКИ
// дерево
export const url_get_employees_tree = `${server_url}/api/employees_tree`; // список сотрудников деревом
// список сотрудников на глоавной team
export const url_get_employees_list = `${server_url}/api/employees_list`;
// создаем сотрудника
export const url_get_data_employee_create = `${server_url}/api/employee_create`;
export const url_get_free_employees = `${server_url}/api/department_create`;
export const url_employee_create_send_data = `${server_url}/api/employees/add`;
//  редактировании сотрудника
export const url_send_edited_employee = `${server_url}/api/employees/edit`;
// создать форму для редактирования сотрудника
export const url_get_data_employee_edit = `${server_url}/api/employee_edit`;
// карточка сотрудника window profile
export const url_get_emplyee_info = `${server_url}/api/employee_info`; // для построения окна профиля
// удаляем сотрудника
export const url_delete_emplyee = `${server_url}/api/employees/delete`;

// РАБОТА С ПРОЕКТАМИ
// строим модалку создания
export const url_get_data_create_project = `${server_url}/api/project_create`;
// отправляем проект
export const url_post_created_project = `${server_url}/api/projects/add`;

// добавим файлы в модалку проекта
export const url_post_add_files = `${server_url}/api/projects/files/add`;
//  удалим загруженные файлы в модалке проекта
export const url_patch_remove_files = `${server_url}/api/projects/files/remove`;
// список проектов
export const url_get_project_list = `${server_url}/api/projects_list`;
// редактируем проект
// создаем окно редактирования
export const url_get_project_edit = `${server_url}/api/project_edit`;
// запрос на редактирование проекта
export const url_put_projects_edit = `${server_url}/api/projects/edit`;
