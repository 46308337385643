import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import useAuth from 'src/hooks/useAuth';
import { downloadFileUrl, generateExcelHtmlUrl } from 'src/settings/base-url';
// import { useDownloader } from '@shared/hooks/useDownloader';
// import iconDownload from '@assets/images/icons/icon-download.svg';
// import { Button, Text } from '@shared/components';

import { PluginContainer, StyledContainer } from './styles';
import { DocViewerDOCXPreview } from './DocViewerDOCXPreview';
import { ExcelViewer } from './ExcelViewer';

const wordDocumentReg = /\.(docx)$/i;
const documentReg = /\.(pdf)$/i;
const excelReg = /\.(xls|xlsx)$/i;

export const DocsViewer = ({ filePath, fileName }) => {
  const auth = useAuth();
  // const { downloadFile } = useDownloader();

  // const docs = [
  //   {
  //     uri: `${generatePdfUrl}${encodeURIComponent(filePath)}?token=${auth.token}`,
  //   },
  // ];
  const docs = [
    {
      uri: `${downloadFileUrl}${encodeURIComponent(filePath)}?token=${auth.token}`,
    },
  ];

  return (
    <PluginContainer>
      <StyledContainer>
        {/* <DocViewerHeader>
          <Text size={4}>{fileName}</Text>

          <Button onClick={() => downloadFile({ url: filePath, filename: fileName })}>
            <img src={iconDownload} alt="скачать" />
          </Button>
        </DocViewerHeader> */}
        {typeof fileName === 'string' && (
          <>
            {wordDocumentReg.test(fileName) && (
              <DocViewerDOCXPreview file={`${downloadFileUrl}${encodeURIComponent(filePath)}?token=${auth.token}`} />
            )}

            {documentReg.test(fileName) && (
              <DocViewer
                config={{
                  pdfVerticalScrollByDefault: false,
                  pdfZoom: {
                    defaultZoom: 0.85,
                  },
                }}
                documents={docs}
                pluginRenderers={DocViewerRenderers}
                language="ru"
              />
            )}

            {excelReg.test(fileName) && (
              <ExcelViewer url={`${generateExcelHtmlUrl}${encodeURIComponent(filePath)}?token=${auth.token}`} />
            )}
          </>
        )}
      </StyledContainer>
    </PluginContainer>
  );
};
