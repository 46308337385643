import React, { useState, useMemo, useRef, useContext, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { object, string, array, date } from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import attachIcon from '@assets/images/icons/icon-file.svg';

import SnackbarContext from 'src/contexts/SnackbarContext';
import SelectMulty from 'src/components/form/select/SelectMulty';
import { CustomOption, CustomValue, getOptions } from 'src/components/form/select/userSelectComponents';
import {
  formatDateforPicker,
  formatReactSelectTagsOptions,
  randomSequence,
  useEventTriggerOnEscPress,
} from 'src/utilize/helper-functions';
import ProjectFormTabs from 'src/windows/projects/project-form-create-edit/ProjectFormTabs';
import ProjectFormResult from 'src/windows/projects/project-form-create-edit/project-form-result/ProjectFormResult';
import CalendarForm, { getFormatCalendarData } from '@shared/components/CalendarForm/CalendarForm';
import useAuth from 'src/hooks/useAuth';
import DropFilesWrapper from '@components/files/DropFilesWrapper';
import FilesUploadManage from 'src/components/files/FilesUploadManage';
import { getProjectStructure } from 'src/redux/features/projectsSlice';
import ConfirmAction from 'src/components/warnings/ConfirmAction';
import Preloader from 'src/components/preloaders/Preloader';
import {
  IconButton,
  Input,
  Label,
  Modal,
  SingleSelect,
  StyledModalFlex,
  StyledModalRowEnd,
  Subtitle,
} from '@shared/components';
import iconLock from '@assets/images/icons/lock.svg';
import iconUnlock from '@assets/images/icons/unlocked.svg';
import { RichTextEditor } from '@components/RichTextEditor';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

const createFormSchema = (modalType) =>
  object({
    title: string().required(),
    executor: object().when([], {
      is: () => modalType === 'request',
      then: (field) => field.required(),
    }),
    description: string(),
    attendee: array(),
    tags: array(),
    deadline_start: date().nullable(true),
    deadline_end: date().when([], {
      is: () => modalType === 'request',
      then: (field) => field.required(),
    }),
  });

const CreateTaskModal = ({
  close,
  projectId,
  parentTaskId,
  sidePanelOpen,
  messageBasis,
  taskIdToEdit,
  getTaskData,
  modalType,
}) => {
  const formSchema = createFormSchema(modalType);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const employees = useSelector((state) => state.users.employees);
  const tags = useSelector((state) => state.tags.tagsList);
  const auth = useAuth();
  const options = useMemo(() => getOptions({ users: employees, token: auth.token }), [employees]);
  const navigate = useNavigate();

  // данные для Select компонента attendee
  const attendee = useMemo(
    () => ({
      name: 'attendee',
      label: '',
      type: 'select',
      optionsType: 'tags',
      options: getOptions(employees) || [],
    }),
    [employees],
  );

  // данные для Select компонента responsible
  const responsible = useMemo(
    () => ({
      name: 'responsible',
      label: '',
      type: 'select',
      subType: 'tag',
      options: employees || [],
      value: 'last_name',
      value2: 'first_name',
    }),
    [employees, t],
  );

  // данные для Select компонента executor
  const executor = useMemo(
    () => ({
      name: 'executor',
      label: '',
      type: 'select',
      subType: 'tag',
      options: employees || [],
      value: 'last_name',
      value2: 'first_name',
    }),
    [employees, t],
  );

  // данные для Select компонента tags
  const tagsData = useMemo(
    () => ({
      name: 'tags',
      label: '',
      // placeholder:'Выбрать тэги',
      // label: t(`${translateKey}.attendee_role`),
      type: 'select',
      subType: 'tag',
      options: formatReactSelectTagsOptions(tags) || [],
    }),
    [tags],
  );

  const {
    register,
    control,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });

  const [lockedTask, setLockedTask] = useState(false);
  const [textFormat, setTextFormat] = useState(null);

  const [formTabs, setFormTabs] = useState({
    result: false,
    deadlines: false,
    tags: false,
    incoming_data: false,
    outgoing_data: false,
  });

  const handlerTab = (tab) => {
    let item = { ...formTabs };
    let value = (item[`${tab}`] = !formTabs[`${tab}`]);
    setFormTabs(item, value);
  };

  const [results, setResults] = useState([]);

  // колонка результата при редактировании
  const [editedItemResult, setEditedItemResult] = useState(null);

  const handlerAddResult = (value, resultId) => {
    // если есть id значит нужно отредачить существующую колонку результата
    if (!resultId) {
      setResults((prev) => [...prev, value]);
    } else {
      const getEditedState = (arr, newItem) => {
        const ind = arr.findIndex(({ id }) => id === newItem.id);
        return [...arr.slice(0, ind), newItem, ...arr.slice(ind + 1)];
      };
      setResults((prev) => getEditedState(prev, value));
      setEditedItemResult(null);
    }
  };

  // назначим редактируемый результат
  const initEditableResult = (value) => {
    setEditedItemResult(value);
  };

  // удалить строку результата
  const deleteResult = (resultToDelete) => {
    setResults((prev) => {
      const resultIndexToDelete = prev.findIndex(({ id }) => id === resultToDelete.id);
      if (resultIndexToDelete === -1) return prev;
      const updResults = [...prev];
      updResults[resultIndexToDelete].toDelete = true;
      return updResults;
    });
  };

  const formId = useRef(randomSequence());

  const [uploadingFiles, setUploadingFiles] = useState([]);

  const onSubmitFiles = (files) => setUploadingFiles([...files]);

  const removeFilesOnClose = useRef();

  const { showSnackbar } = useContext(SnackbarContext);

  const [isSubmitting, setIsSubmitting] = useState();

  const [isLoadingEditData, setIsLoadingEditData] = useState();
  const [taskDataToEdit, setTaskDataToEdit] = useState();
  const [filesDeleteArr, setFilesDeleteArr] = useState([]);

  const { filesAddFormData, filesToEdit, removeFilesReqBody } = useMemo(() => {
    if (!taskDataToEdit) return {};
    const filesAddFormData = { type: 'task_id', id: taskDataToEdit.id };
    let filesToEdit, removeFilesReqBody;
    if (taskDataToEdit.task_files?.length && auth?.token) {
      filesToEdit = taskDataToEdit.task_files.map((file) => ({
        ...file,
        name: file.file,
        src: `/tasks/files/${taskDataToEdit.id}/${file.file}`,
      }));
      removeFilesReqBody = { task_id: taskDataToEdit.id };
    }

    return { filesAddFormData, filesToEdit, removeFilesReqBody };
  }, [taskDataToEdit]);

  const defaultValue = useMemo(
    () =>
      taskDataToEdit?.description
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(String(taskDataToEdit?.description)).contentBlocks,
              convertFromHTML(String(taskDataToEdit?.description)).entityMap,
            ),
          )
        : '',
    [taskDataToEdit?.description],
  );

  const openFileSelectPopup = () => {
    document.getElementById('upfiletask').click();
  };
  // если открывается окно редактирования, то получить и внедрить все данные для редактирования
  useEffect(() => {
    if (taskIdToEdit) {
      setIsLoadingEditData(true);
      axios
        .get(`/api/task_edit/${taskIdToEdit}`)
        .then((r) => {
          const taskData = r.data.result;
          const attendeeOptions = getOptions({ users: taskData.task_members, token: auth.token });
          const options = getOptions({ users: taskData.members, token: auth.token });

          reset({
            title: taskData.title,
            attendee: attendeeOptions,
            responsible: options.filter((user) => user.value.id === Number(taskData.responsible_id))[0],
            executor: options.filter((user) => user.value.id === Number(taskData.executor_id))[0],
            deadline_start: formatDateforPicker(taskData.date_start),
            deadline_end: formatDateforPicker(taskData.date_finish),
            tags: taskData.task_tags?.map((tag) => ({
              color: tag.color,
              id: tag.tag_id,
              label: tag.name,
              value: tag.name,
            })),
          });
          const defFormTabs = {
            result: false,
            deadlines: false,
            tags: false,
            incoming_data: false,
            outgoing_data: false,
          };
          if (taskData.task_goals?.length) {
            defFormTabs.result = true;
            setResults(taskData.task_goals.map((goal) => ({ ...goal, goal_id: goal.id })));
          }
          if (taskData.date_start || taskData.date_finish) {
            defFormTabs.deadlines = true;
          }
          if (taskData.task_tags?.length) {
            defFormTabs.tags = true;
          }
          setFormTabs(defFormTabs);
          setTaskDataToEdit(taskData);
          setLockedTask(!!taskData.locked);
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при запросе данных на редактирование');
        })
        .finally(() => setIsLoadingEditData(false));
    }
  }, []);

  const submitData = (data) => {
    if (auth) {
      const submitData = {
        row: {
          project_id: parentTaskId ? null : projectId,
          parent_task_id: parentTaskId,
          locked: lockedTask,
          title: data.title,
          description: textFormat ? stateToHTML(textFormat.getCurrentContent()) : '',
          creator_id: auth.user.id,
          executor_id: modalType === 'request' ? data.executor?.value.id : null,
          //responsible_id: modalType === 'request' ? auth.user.id : null,
          base_chat_message_id: messageBasis ? messageBasis.message_id : null,
          date_start: modalType === 'request' ? getFormatCalendarData(new Date()) : null,
          date_finish: modalType === 'request' ? getFormatCalendarData(data.deadline_end) : null,
          type: modalType,
        },
        task_members: [],
        form_id: formId.current,
        task_id: taskIdToEdit,
      };
      // if (formTabs.deadlines) {
      //   submitData.row.date_start = getFormatCalendarData(data.deadline_start);
      //   if (data.deadline_end) {
      //     submitData.row.date_finish = getFormatCalendarData(data.deadline_end);
      //   }
      // }
      if (formTabs.tags && data.tags?.length) {
        submitData.task_tags = data.tags.map((tag) => ({ tag_id: tag.id }));
      }
      if (data.attendee?.length) {
        submitData.task_members = data.attendee.map((member) => ({
          employee_id: member.value.id,
        }));
      }
      if (formTabs.result && results.length) {
        if (!taskIdToEdit) {
          submitData.task_goals = results.map((result) => ({
            type: result.type,
            description: result.description,
          }));
        } else {
          submitData.add_task_goals = [];
          submitData.edit_task_goals = [];
          submitData.delete_task_goals = [];

          for (const result of results) {
            if (result.toDelete) {
              submitData.delete_task_goals.push(result.goal_id);
            } else if (result.isEdited) {
              submitData.edit_task_goals.push({
                type: result.type,
                description: result.description,
                task_goal_id: result.goal_id,
              });
            } else if (!result.goal_id) {
              submitData.add_task_goals.push({
                type: result.type,
                description: result.description,
              });
            }
          }
        }
      }

      setIsSubmitting(true);
      if (taskIdToEdit) {
        if (filesDeleteArr.length) {
          axios
            .patch('/api/tasks/files/remove', {
              task_id: taskIdToEdit,
              files: filesDeleteArr.map((f) => f.file),
            })
            .catch(() => showSnackbar('Возникла ошибка при удалении файлов'));
        }
        axios
          .put('/api/tasks/edit', submitData)
          .then(() => {
            getTaskData();
            showSnackbar('Задача отредактирована', 'success');
            close();
          })
          .catch(() => {
            showSnackbar('Возникла ошибка при редактировании задачи');
            setIsSubmitting(false);
          });
        return;
      }

      axios
        .post('/api/tasks/add', submitData)
        .then((response) => {
          //
          if (sidePanelOpen) dispatch(getProjectStructure({ projectId }));
          navigate(`/projects/${projectId}/tasks/${response.data.result}`);
          close();
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при создании задачи');
          setIsSubmitting(false);
        });
    }
  };

  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  const handleModalClose = useCallback(() => {
    if (
      isDirty ||
      uploadingFiles.length ||
      (!taskIdToEdit && results?.length) ||
      (taskIdToEdit && results?.some((result) => result.isEdited || !result.goal_id)) ||
      textFormat
    ) {
      setModalClosePrompt(true);
    } else close();
  }, [isDirty, results, textFormat, uploadingFiles]);

  // регистрация eventa keypress для выхода из модального окна в случае нажатии на кнопку Esc
  useEventTriggerOnEscPress(handleModalClose);

  let title = '';

  if (taskIdToEdit) {
    if (modalType === 'rubric') {
      title = 'Редактировать раздел';
    } else if (modalType === 'request') {
      title = 'Редактировать просьбу';
    }
  } else {
    if (modalType === 'rubric') {
      title = 'Создать раздел';
    } else if (modalType === 'request') {
      title = 'Создать просьбу';
    }
  }

  return (
    <Modal
      title={title}
      onClose={handleModalClose}
      confirmButtonText={!isSubmitting && (taskIdToEdit ? t('FormButtons.save') : t('FormButtons.create'))}
      disabledSaveButton={!isValid || isSubmitting}
      onSave={handleSubmit(submitData)}
    >
      {messageBasis && (
        <div>
          {t('Project.message_basis')}
          <strong>{` ${messageBasis.author} `}</strong>
          <div>
            {t('common.date') + ' '}
            <strong>
              {/* <LocalDateTime dateTime={messageBasis.date_created} /> */}
              {messageBasis.LocalDateTime}
            </strong>
          </div>
        </div>
      )}

      {isLoadingEditData ? (
        <Preloader />
      ) : (
        <DropFilesWrapper setFiles={setUploadingFiles}>
          <form>
            <div>
              <Label htmlFor="name">
                {t('common.title_name')} <sup>*</sup>
              </Label>
              <Input
                error={!!errors.title}
                id="name"
                placeholder={t('common.enter_title')}
                register={register('title')}
              />
            </div>

            <div>
              <Label htmlFor="description">{t('Project.description')}</Label>

              <RichTextEditor
                value={defaultValue}
                onChange={(value) => {
                  setTextFormat(value);
                }}
                extraButtons={
                  <IconButton size={14} icon={attachIcon} onClick={openFileSelectPopup}>
                    <input
                      type="file"
                      multiple
                      id="upfiletask"
                      style={{ display: 'none' }}
                      onChange={(e) => setUploadingFiles([...e.target.files])}
                    />
                  </IconButton>
                }
              />
              {(uploadingFiles.length > 0 || filesToEdit?.length) && (
                <FilesUploadManage
                  filesToUpload={uploadingFiles}
                  filesToEdit={filesToEdit}
                  filesDeleteArr={filesDeleteArr}
                  setFilesDeleteArr={setFilesDeleteArr}
                  formId={formId.current}
                  removeFilesOnClose={removeFilesOnClose}
                  removeFilesReqBody={removeFilesReqBody}
                  filesUploadPath="/api/tasks/files/add"
                  filesDeletePath="/api/tasks/files/remove"
                  filesAddFormData={filesAddFormData}
                />
              )}
            </div>

            {/* Участники и роли */}
            <div>
              <StyledModalFlex>
                <Subtitle>{t('Project.members_roles')}</Subtitle>
                <IconButton icon={lockedTask ? iconLock : iconUnlock} onClick={() => setLockedTask(!lockedTask)} />
              </StyledModalFlex>

              <SelectMulty
                item={attendee}
                control={control}
                options={options}
                customOption={CustomOption}
                getOptionValue={(option) => option.value.id}
              />
            </div>

            {/* ответсвенный, исполнитель, дедлайн */}
            {/*    <StyledModalRowStart>*/}
            {/*      <div>*/}
            {/*        <Label>*/}
            {/*          {t('Project.responsible')} <sup>*</sup>*/}
            {/*        </Label>*/}
            {/*        <Controller*/}
            {/*          name={responsible.name}*/}
            {/*          control={control}*/}
            {/*          render={({ field }) => (*/}
            {/*            <SingleSelect*/}
            {/*              {...field}*/}
            {/*              options={options}*/}
            {/*              components={{ Option: CustomOption, SingleValue: CustomValue }}*/}
            {/*            />*/}
            {/*          )}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </StyledModalRowStart>*/}

            {modalType === 'request' && (
              <StyledModalRowEnd>
                <div>
                  <Label>
                    {t(`Project.executor`)} <sup>*</sup>
                  </Label>
                  <Controller
                    name={executor.name}
                    control={control}
                    render={({ field }) => (
                      <SingleSelect
                        {...field}
                        options={options}
                        components={{ Option: CustomOption, SingleValue: CustomValue }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Label>
                    Дедлайн <sup>*</sup>
                  </Label>
                  <CalendarForm
                    data={{
                      name: 'deadline_end',
                      placeholder: t('Project.end_date'),
                    }}
                    control={control}
                    minDate={new Date()}
                    //prev={projectData?.date_finish}
                  />
                </div>
              </StyledModalRowEnd>
            )}

            {/* сроки результат и тд., вкладки, разворачивающиеся  при клике */}
            <ProjectFormTabs handler={handlerTab} tabs={formTabs} />

            {/* результат */}
            {formTabs.result && (
              <ProjectFormResult
                // tab="result"
                results={results}
                setResult={handlerAddResult}
                initEditableResult={initEditableResult}
                editedItemResult={editedItemResult}
                deleteResult={deleteResult}
              />
            )}

            {/* сроки  */}
            {/*{formTabs.deadlines && (*/}
            {/*  <div>*/}
            {/*    <StyledModalFlex>*/}
            {/*      <Subtitle>{t(`Project.deadlines`)}</Subtitle>*/}
            {/*    </StyledModalFlex>*/}
            {/*    <StyledModalRowStart>*/}
            {/*      <CalendarForm*/}
            {/*        data={{*/}
            {/*          name: 'deadline_start',*/}
            {/*          placeholder: t('Project.start_date'),*/}
            {/*        }}*/}
            {/*        control={control}*/}
            {/*        minDate={new Date()}*/}
            {/*        //prev={projectData?.date_start}*/}
            {/*      />*/}

            {/*      <CalendarForm*/}
            {/*        data={{*/}
            {/*          name: 'deadline_end',*/}
            {/*          placeholder: t('Project.end_date'),*/}
            {/*        }}*/}
            {/*        control={control}*/}
            {/*        minDate={new Date()}*/}
            {/*        //prev={projectData?.date_finish}*/}
            {/*      />*/}
            {/*    </StyledModalRowStart>*/}
            {/*  </div>*/}
            {/*)}*/}

            {/* тэги */}
            {formTabs.tags && (
              <div>
                <StyledModalFlex>
                  <Subtitle>{t('common.tags')}</Subtitle>
                </StyledModalFlex>
                <SelectMulty item={tagsData} control={control} options={tagsData.options} />
              </div>
            )}
          </form>
        </DropFilesWrapper>
      )}

      {modalClosePrompt && (
        <ConfirmAction
          cancel={() => setModalClosePrompt(false)}
          confirm={() => {
            if (typeof removeFilesOnClose.current === 'function') removeFilesOnClose.current();
            close();
          }}
          actionText={t('common.confirm_modal_close')}
        />
      )}
    </Modal>
  );
};

export default CreateTaskModal;
