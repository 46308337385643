import { shape, array, number, string } from 'prop-types';

export const TaskItemPropType = shape({
  id: number,
  title: string,
  description: string,
  creator_id: number,
  executor_id: number,
  responsible_id: number,
  locked: number,
  project_id: number,
  parent_task_id: number,
  base_chat_message_id: number,
  status: string,
  type: string,
  date_start: string,
  date_finish: string,
  date_real_start: string,
  date_real_finish: string,
  deleted: number,
  date_update: string,
  date_created: string,
  creator_first_name: string,
  creator_last_name: string,
  executor_first_name: string,
  executor_last_name: string,
  responsible_first_name: string,
  responsible_last_name: string,
  task_tags: array,
  project_title: string,
  project_type: string,
  root_project: number,
  project_date_start: string,
  project_date_finish: string,
});
