import { getOptions } from '@components/form/select/userSelectComponents';
import { SingleSelect } from '@shared/components';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import useAuth from 'src/hooks/useAuth';
import { CustomOption, CustomValue } from 'src/components/form/select/userSelectComponents';
import { useGetSubordinateEmployeesQuery } from 'src/redux/features/api/employees';

import { StyledEmployeeSelectWrapper } from './style';

export function SubordinateSelect({ control }) {
  const auth = useAuth();
  const { data } = useGetSubordinateEmployeesQuery();

  const employeeOptions = useMemo(
    () =>
      getOptions({
        users: Array.isArray(data?.employees) ? [{ first_name: 'Я', id: '' }, ...data.employees] : null,
        token: auth.token,
      }),
    [data?.employees, auth?.token],
  );

  return (
    <StyledEmployeeSelectWrapper>
      <Controller
        name="subordinate"
        control={control}
        render={({ field }) => {
          return (
            <SingleSelect
              options={employeeOptions}
              components={{ Option: CustomOption, SingleValue: CustomValue }}
              getOptionValue={(option) => option.value.id}
              {...field}
              ref={null}
            />
          );
        }}
      />
    </StyledEmployeeSelectWrapper>
  );
}
