import { styled } from 'styled-components';
import { Link } from 'react-router-dom';

export const TasksContainer = styled.section`
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 24px;

  @media (max-width: 550px) {
    padding: 0 12px;
  }
`;

export const SelectItem = styled.span`
  align-items: center;
  display: flex;
  white-space: nowrap;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 2px;
  padding: 1px 8px;
  font-size: 12px;
  line-height: 20px;
`;

export const ParentProjectWrapper = styled.section`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  line-height: 20px;
  margin-top: -8px;
  margin-bottom: 12px;
`;

export const ProjectLink = styled(Link)`
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.main};
  }
`;
